import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import appconfig from '../appConfig'
import { tokenRequest } from "../auth/authConfig";
import { useAccount, useMsal } from "@azure/msal-react";
import {Grid , MenuItem } from "@mui/material";
import TextField from "../components/TextField";
import CustomDatePicker from "../components/DatePicker";
import SelectorTextField from "../components/SelectorTextField";
import {TextButton} from "../components/Button";
import StyledModal from '../components/Modal';
import { GetClientFinancialInformation } from "../services/client-file.service";

const ClientFinancialInfo = forwardRef((props, ref) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const apiServerUrl = appconfig.API_SERVER_URL;

    const [clientFinancialInformationId, setClientFinancialInformationId] = useState(0);
    const [financiallyEligible, setFinanciallyEligible] = useState('');
    const [orginalfinanciallyEligible, setOrginalfinanciallyEligible] = useState('');
    const [familySize, setFamilySize] = useState('');
    const [familySizeError, setFamilySizeError] = useState(false);
    const [last12MonthsIncome, setLast12MonthsIncome] = useState('');
    const [showLast12MonthsIncome, setShowLast12MonthsIncome] = useState('');
    const [last12MonthsIncomeError, setLast12MonthsIncomeError] = useState(false);
    const [last30DaysIncome, setLast30DaysIncome] = useState('');
    const [showLast30DaysIncome, setShowLast30DaysIncome] = useState('');
    const [last30DaysIncomeError, setLast30DaysIncomeError] = useState(false);
    const [totalEquityValue, setTotalEquityValue] = useState('');
    const [showTotalEquityValue, setShowTotalEquityValue] = useState('');
    const [totalEquityValueError, setTotalEquityValueError] = useState(false);
    const [lastAssessmentDate, setLastAssessmentDate] = useState("");
    const [financialInformationRequired, setFinancialInformationRequired] = useState(true);
    const [showDate, setShowDate] = useState("");
    const [createdOn, setCreatedOn] = useState("");
    const [createdBy, setCreatedBy] = useState("");

    useImperativeHandle(ref, () => ({
        getClientFinancialInfo() {
            return {
                "clientFinancialInformationId": clientFinancialInformationId,
                "financiallyEligible": orginalfinanciallyEligible,
                "familySize": familySize != ''? familySize: null,
                "last12MonthsIncome": (last12MonthsIncome == null )  ? null : (last12MonthsIncome != 0 ? Number(last12MonthsIncome)  : "zero" ),
                "last30DaysIncome": (last30DaysIncome == null )  ? null : (last30DaysIncome != 0 ?  Number(last30DaysIncome) : "zero" ),
                "totalEquityValue":  (totalEquityValue == null ) ? null : (totalEquityValue != 0 ?  Number(totalEquityValue): "zero" ),
                "lastAssessmentDate": (null == lastAssessmentDate || "" == lastAssessmentDate) ? null : new Date(lastAssessmentDate),
                "createdOn": createdOn,
                "createdBy": createdBy,
                "mostRecentAssessmentOver24Months": financialInformationRequired
            };
        }
    }));

    useEffect(() => {


        if (!props.clientFile && props.clientFileId) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        LoadClientFinancialInfromation(response.accessToken, props.clientFileId);
                    }
                });
            }
        }
        else if (props.clientFile) {
            setMainInformation(props.clientFile.clientFinancialInformation);
        };
    }, [account, instance]);

    const LoadClientFinancialInfromation = async (token, clientFileId) => { 

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await GetClientFinancialInformation(token, clientFileId).then((data) => {
                        if (data)
                            setMainInformation(data);
                    })
                }
            });
        }
    }

    function setMainInformation(data) {
        setClientFinancialInformationId(data.clientFinancialInformationId);
        setOrginalfinanciallyEligible(data?.financiallyEligible);
        setFinanciallyEligible(data?.financiallyEligible != null ? Number(data.financiallyEligible) : '');
        setFamilySize(data.familySize);
        setShowLast12MonthsIncome(data?.last12MonthsIncome != null ? data.last12MonthsIncome.toLocaleString("en-US") : "");
        setLast12MonthsIncome(data?.last12MonthsIncome);
        setFinancialInformationRequired(data?.mostRecentAssessmentOver24Months != null ? data.mostRecentAssessmentOver24Months : true);
        setShowLast30DaysIncome(data?.last30DaysIncome != null ? data.last30DaysIncome.toLocaleString("en-US") : "");
        setLast30DaysIncome(data?.last30DaysIncome);
        setShowTotalEquityValue(data?.totalEquityValue != null ? data.totalEquityValue.toLocaleString("en-US") : "");
        setTotalEquityValue(data?.totalEquityValue);
        setLastAssessmentDate((null == data.lastAssessmentDate || "" == data.lastAssessmentDate) ? null : Date.parse(data.lastAssessmentDate));
        setShowDate((null == data.lastAssessmentDate || "" == data.lastAssessmentDate) ? null : new Date(data.lastAssessmentDate).toLocaleDateString());
        setCreatedOn((null == data.createdOn || "" == data.createdOn) ? null : new Date(data.createdOn));
        setCreatedBy(data.createdBy);
    }

    const onLastAssessmentDateChanged = (date) => {
        setShowDate(date);
        setLastAssessmentDate(Date.parse(date));
    };

    const [openModal, setOpenModal] = useState(false);
    const handleModalOpen = () => {
        setOpenModal(true);
    };
    const handleModalClose = () => {
        setOpenModal(false);
    };
    return (
        <Grid container style={{padding: "10px 20px 0px 20px",marginBottom:props.isCRMOnly ? "1px": null,backgroundColor: props.isCRMOnly ? "#ececec" : null , border: props.fromViewDeatils ? null : "1px solid #7F8284", borderRadius:"8px"}}>
            <Grid item xs={8} md={8} style={{ textAlign: "left", font: "normal normal bold 17px/22px Franklin Gothic Medium", letterSpacing: "0px", color:" #000000", opacity: "1", marginBottom:"10px"}}> {props.fromViewDeatils ? "" : "Client Financial Information"}</Grid>
            {!props.isResolveCrmConflicts && (<Grid item xs={4} md={4}  style={{ textAlign: "right"}}><TextButton label="Financial Eligibility Guidelines" size="small" handleOnClick={handleModalOpen} /></Grid>)}

            <StyledModal open={openModal} title="Financial Eligibility" handleOnClose={handleModalClose} />

            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={3} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
                <SelectorTextField label=" Financially Eligible " placeholder=" Financially Eligible " customwidth={props.fromViewDeatils ? "199px" : "229px"}
                    disabled={props.isCRMOnly? true : null}
                    select value={financiallyEligible}
                >
                    <MenuItem key='' value=""></MenuItem>
                    <MenuItem key="1" value={1}>Yes</MenuItem>
                    <MenuItem key="0" value={0}>No</MenuItem>
                </SelectorTextField>
            </Grid>
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={3} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
            <TextField label="Family Size" placeholder="Family Size" customwidth={props.fromViewDeatils ? "217px" : "247px"}
                disabled={props.isCRMOnly ? true : null}
                value={familySize} />
            </Grid>
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={3} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
            <TextField label="Last 12 Months Income ($)" placeholder="Last 12 Months Income" customwidth={props.fromViewDeatils ? "217px" : "247px"}
                disabled={props.isCRMOnly ? true : null}
                value={showLast12MonthsIncome} />
            </Grid>
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={3} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
                <SelectorTextField
                    label="Financial Information Required?"
                    placeholder="Financial Information Required?"
                    disabled={props.isCRMOnly? true : null}
                    select
                    value={financialInformationRequired}
                    labelColor="#4f868e"
                    customwidth={props.fromViewDeatils ? "199px" : "229px"}
                >
                    <MenuItem key="-1" value=""></MenuItem>
                    <MenuItem key="true" value={true}>Yes</MenuItem>
                    <MenuItem key="false" value={false}>No</MenuItem>
                </SelectorTextField>
            </Grid>
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={3} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
                <TextField label="Last 30 Days Income ($)" placeholder="Last 30 Days Income" customwidth={props.fromViewDeatils ? "217px" : "247px"}
                disabled={props.isCRMOnly ? true : null}
                value={showLast30DaysIncome} />
            </Grid>
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={3} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
            <TextField label="Total Equity Value ($)" placeholder="Total Equity Value" customwidth={props.fromViewDeatils ? "217px" : "247px"}
                disabled={props.isCRMOnly ? true : null}
                value={showTotalEquityValue} />
            </Grid>
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={3} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
                <CustomDatePicker label="Last Assessment Date" placeholder="Last Assessment Date" disableFuture={true} customwidth={props.fromViewDeatils ? "245px" : "275px"}
                    disabled={props.isCRMOnly? true : null}
                    value={showDate}
                    handleOnChange={onLastAssessmentDateChanged}
                />
            </Grid>
        </Grid>
    );
})

export default ClientFinancialInfo;