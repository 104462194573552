import React from "react";
import { forwardRef, useRef, useReducer, useImperativeHandle, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientBasicInfo from "../client-basic-info";
import ClientContactInfo from "../client-contact-info";
import ClientFinancialInfo from "../client-financial-info";
import NoteTable from "../notes";
import ReferralTable from "../referrals";
import DocketsLegalIssues from "../client-dockets-legal-issues";
import CertificateDataGrid from "../client-certificate";
import CertificateOutcome from '../client-certificate-outcome';
import ServiceDecision from '../client-service-decision';
import AgencyRequestList from "../feature/agency-requests/agency-request-list";
import JPBailOutcomeList from "../feature/jp-bail-outcomes/jp-bail-outcome-list";
import InteractionList from "../feature/interactions/interaction-list";
import ClientGatewayTable from "../feature/client-gateway/client-gateway-details";
import appconfig from '../appConfig'
import { useAuthorization } from "../hooks/useAuthorization";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import { GetAllAgencyRequest } from "../services/agency-request-service";
import { GetAllClientReferrals } from "../services/referral-service";
import { GetAllJPBailOutcomes } from "../services/jp-bail-outcome-service";
import { GetAllInteractions } from "../services/interaction-service";
import { GetAllLegalIssues } from "../services/legal-issues-service";
import { GetAllCertificates } from "../services/certificate-service";
import { GetAllCertificatesOutcomes } from "../services/certificates-outcomes-service";
import { GetAllServiceDecisions } from "../services/service-decision-service";
import { GetClientDetailNotes } from "../services/note-service";
import { Grid } from "@mui/material";
import SubHeader from '../components/SubHeader';
import StyledDialog from '../components/Dialog';
import ScreenSideBar from "../components/ScreenSideBar";
import { GetClientFileById, MarkClientFileAsCrmConflicted, MarkClientFileAsDcpConflicted, MarkClientFileAsConflicted, GetMergedClientFile, CheckDuplicateClients, UpdateClientFile, CreateClientFile } from "../services/client-file.service";
import Badge from 'react-bootstrap/Badge';
import { SEARCHRESULTSPAGEURL, CRMCONFLICTDASHBOARDPAGEURL, REVIEWDASBOARDPAGEURL, CLIENTFILEDASHBOARDPAGEURL } from '../helper/constants';
import Backdrop from '../components/elements/Loader';
import { toast } from "react-toastify";
import { GetAllClientGateways } from '../services/client-gateway-service';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import { FetchLookupData, DeleteClientFile } from "../services/client-file.service";
import { useNavigationParameters } from "../hooks/useNavigationParameters";
import * as Constants from "../helper/constants";

function ClientFile() {

    const CREATESUCCESS = "Client File/Details successfully created.";
    const UPDATESUCCESS = "Client File/Details successfully updated.";

    const apiServerUrl = appconfig.API_SERVER_URL;

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [contactMethods, setContactMethods] = useState([]);
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const [agencyRequests, setAgencyRequests] = useState([]);
    const [jpBailOutcomes, setJpBailOutcomes] = useState([]);
    const [interactions, setInteractions] = useState([]);
    const [legalIssues, setLegalIssues] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [certificatesOutcomes, setCertificatesOutcomes] = useState([]);
    const [serviceDecisions, setServiceDecisions] = useState([]);
    const [clientGatewayDetails, setClientGatewayDetails] = useState([]);
    const screenSideBarList = ['Client Information', 'Client Notes', 'DC Triage', 'CRM History', 'JP Bail Outcomes', 'DC Portal Activity'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [isCrmConflicted, setIsCrmConflicted] = useState(false);
    const [hasDcpDuplicateClientFiles, setHasDcpDuplicateClientFiles] = useState(false);
    const [isLinkedClient, setIsLinkedClient] = useState(false);
    const [isDcpConflicted, setIsDcpConflicted] = useState(false);
    const [isCrmConflictResolved, setIsCrmConflictResolved] = useState(false);
    const [crmData, setCrmData] = useState(null);
    const [clientDetailNotes, setClientDetailNotes] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [backPage, setBackPage] = useState(searchParams.get("page"));
    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstName, setFirstName] = useState(searchParams.get("firstname"));
    const [middleName, setMiddleName] = useState(searchParams.get("middlename"));
    const [lastName, setLastName] = useState(searchParams.get("lastname"));
    const [dateOfBirth, setDateOfBirth] = useState(searchParams.get("dateofbirth"));
    const [fromPageValue, setFromPageValue] = useState(searchParams.get("from"));
    const [isScrolledToCRMHistory, setIsScrolledToCRMHistory] = useState(false);
    const scrollToCRMHistoryRef = useRef(null);
    const [isDataReady, setIsDataReady] = useState(false);
    const [clientFileCrmIdById, setClientFileCrmIdById] = useState("");
    const [saveClicked, setSaveClicked] = useState(false);
    const [canUpdateBasicInfo, setCanUpdateBasicInfo] = useState(false);
    const [saveOption, setSaveOption] = useState(-1);
    const [dcpClient, setDcpClient] = useState(null);
    const [trackEvents, setTrackEvents] = useState("");
    const appInsights = useAppInsightsContext();
    const trackEventsInsights = useTrackEvent(appInsights, "Client File : ", trackEvents);

    useEffect(() => {
        const currentAccount = instance.getActiveAccount();

        const fetchInitialData = async (token) => {
            await GetAllAgencyRequest(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)), token).then((data) => {
                setAgencyRequests(data);
            });
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    var clientFileId = Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM));
                    var clientFileCrmId = searchParams.get("crmId");
                    if (clientFileId || clientFileId) {
                        setCanUpdateBasicInfo((currentAccount.idTokenClaims.roles[0].toString() == 'DutyCounselTriageLead') ? true : false);
                    }
                    else {
                        setCanUpdateBasicInfo(true);
                    }
                    setCrmData(null);
                    if (clientFileId && clientFileId > 0) {
                        await GetClientFileById(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)), response.accessToken).then((data) => {
                            setDcpClient(data);
                            if (data) {
                                if ((clientFileCrmId == null || clientFileCrmId == "") && (data.clientFileCrmId != null && data.clientFileCrmId != "")) {
                                    clientFileCrmId = data.clientFileCrmId;
                                    setCrmId(clientFileCrmId);
                                }
                            }

                            if ((clientFileCrmId && clientFileCrmId != "") && (clientFileId && clientFileId > 0)) {
                                setCrmData(data);
                                setIsCrmConflicted(data.isCrmConflicted);
                                setIsDcpConflicted(data.isDcpConflicted);
                                setIsCrmConflictResolved(false);

                                setIsLinkedClient((null != data.clientFileCrmId && "" != data.clientFileCrmId))
                                CheckDuplicateClients(data.clientBasicInformation.firstName, data.clientBasicInformation.middleName, data.clientBasicInformation.lastName, data.clientBasicInformation.dateOfBirth, data.clientFileId, response.accessToken).then(data2 => {
                                    setHasDcpDuplicateClientFiles(data2);
                                });
                                // Already Linked, Show CRM Data
                                GetAllCertificates(clientFileCrmId, response.accessToken).then((data) => {
                                    setCertificates(data);
                                });
                                GetAllServiceDecisions(clientFileCrmId, response.accessToken).then((data) => {
                                    setServiceDecisions(data);
                                });
                                GetAllClientGateways(clientFileId, response.accessToken).then(data => {
                                    setClientGatewayDetails(data);
                                });

                            } else if ((clientFileCrmId && clientFileCrmId != "") && (!clientFileId || clientFileId == 0)) {
                                // CRM Only Record, Not Posible on this page;
                            } else if ((!clientFileCrmId || clientFileCrmId == "") && (clientFileId && clientFileId > 0)) {
                                // DCP Only Record, Show from DCP
                                GetClientFileById(clientFileId, response.accessToken).then(async data => {
                                    setIsCrmConflicted(data.isCrmConflicted);
                                    setIsDcpConflicted(data.isDcpConflicted);
                                    setIsCrmConflictResolved(data.isCrmConflictResolved);
                                    if (data?.clientFileCrmId != null) {

                                        setClientFileCrmIdById(data?.clientFileCrmId);
                                        GetAllLegalIssues(data?.clientFileCrmId, response.accessToken).then((data) => {
                                            setLegalIssues(data);
                                        });
                                        GetAllCertificates(data?.clientFileCrmId, response.accessToken).then((data) => {
                                            setCertificates(data);
                                        });
                                        GetAllCertificatesOutcomes(data?.clientFileCrmId, response.accessToken).then((data) => {
                                            setCertificatesOutcomes(data);
                                        });
                                        GetAllServiceDecisions(data?.clientFileCrmId, response.accessToken).then((data) => {
                                            setServiceDecisions(data);
                                        });
                                    }
                                    setIsLinkedClient((null != data.clientFileCrmId && "" != data.clientFileCrmId))
                                    CheckDuplicateClients(data.clientBasicInformation.firstName, data.clientBasicInformation.middleName, data.clientBasicInformation.lastName, data.clientBasicInformation.dateOfBirth, data.clientFileId, response.accessToken).then(data2 => {
                                        setHasDcpDuplicateClientFiles(data2);
                                    });
                                    await GetAllClientGateways(clientFileId, response.accessToken).then(data => {
                                        setClientGatewayDetails(data);
                                    });
                                });
                            }
                        });
                    }
                    await fetchInitialData(response.accessToken);
                }
            }).then(() => {setIsDataReady(true)});
        }
    }, []);

    useEffect(() =>{
        scrollToCRMHistory();
    },[scrollToCRMHistoryRef.current]);

    useEffect(() => {
        trackEventsInsights();
    }, [trackEvents]);

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const clientFinancialInfoComp = useRef();

    const [openCreateDuplicateClientConfirmDialog, setOpenCreateDuplicateClientConfirmDialog] = useState(false);

    const onCreateDuplicateClientConfirmClickOpen = () => {
        setOpenCreateDuplicateClientConfirmDialog(true);
    };

    const onCreateDuplicateClientConfirmClickClose = () => {
        setOpenCreateDuplicateClientConfirmDialog(false);
        // Create a DCP Conflicted Client File Aborted
        toast.success("Create Client File aborted.", { position: "top-center", autoClose: 5000, theme: "colored" });
    };

    const onCreateDuplicateClientConfirmClicked = () => {
        setOpenCreateDuplicateClientConfirmDialog(false);
        // Create a DCP Conflicted Client File
        saveClientDetails(true, true, saveOption);
    };

    const onSaveClicked = async (option) => {
        if (validateInformation()) {
            var dcpClientId = ((null != searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) && (Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) > 0)) ?
                Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) : 0;
            if (dcpClientId == 0) setSaveClicked(true);
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then(async (response) => {
                    if (response) {

                        var hasDuplicateClients = await CheckDuplicateClients(clientBasicInfoComp.current.getClientBasicInfo().firstName, clientBasicInfoComp.current.getClientBasicInfo().middleName, clientBasicInfoComp.current.getClientBasicInfo().lastName, clientBasicInfoComp.current.getClientBasicInfo().dateOfBirth, dcpClientId, response.accessToken);

                        switch (dcpClientId) {
                            case 0:
                                // Create Client File
                                switch (hasDuplicateClients) {
                                    case true:
                                        // Duplicate records exist
                                        // Get Confirmation
                                        onCreateDuplicateClientConfirmClickOpen();
                                        break;
                                    default:
                                        // No Duplicate records
                                        // Create a Non DCP Conflicted Client File
                                        saveClientDetails(null, true, option);
                                        break;
                                }
                                break;
                            default:
                                // Update Client Details
                                switch (hasDuplicateClients) {
                                    case true:
                                        // Duplicate records exist
                                        // Not allowed to update
                                        toast.error("Duplicate Client Files detected with the same First Name, Last Name and Date of Birth. Please verify form data and try again.", {
                                            position: "top-center", autoClose: 5000, theme: "colored"
                                        });
                                        break;
                                    default:
                                        // No Duplicate records
                                        // Update Non DCP Conflicted Client Details
                                        saveClientDetails(null, false, option);
                                        break;
                                }
                                break;
                        }
                    }
                });
            }
        } else {
            // Incorrect Form Data
            toast.error("Please fill out the details correctly before saving the form.", {
                position: "top-center", autoClose: 5000, theme: "colored"
            });
        }
    }

    const saveClientDetails = async (isDcpConflicted, isCreate, option) => {
        var clientFileId = ((null != searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) && (Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) > 0)) ?
            Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) : 0;
        var clientFileCrmId = (!crmId || crmId == "") ? (clientFileCrmIdById && clientFileCrmIdById != "" ? clientFileCrmIdById : null) : crmId;
        //var isCrmConflicted = null;
        var updatedDateOfBirth = new Date(clientBasicInfoComp.current.getClientBasicInfo().dateOfBirth).setHours(0, 0, 0);

    var newClientDetails = {
        "ClientFileId": clientFileId,
        "clientFileCrmId": clientFileCrmId,
        "isCrmConflicted": isCrmConflicted,
        "isDcpConflicted": isDcpConflicted,
        "clientBasicInformation": {
            "clientBasicInformationId": isCreate ? 0 : clientBasicInfoComp.current.getClientBasicInfo().clientBasicInformationId,
            "firstName": clientBasicInfoComp.current.getClientBasicInfo().firstName,
            "middleName": clientBasicInfoComp.current.getClientBasicInfo().middleName,
            "lastName": clientBasicInfoComp.current.getClientBasicInfo().lastName,
            "dateOfBirth": new Date(updatedDateOfBirth),
            "clientAlias1": clientBasicInfoComp.current.getClientBasicInfo().clientAlias1,
            "clientAlias2": clientBasicInfoComp.current.getClientBasicInfo().clientAlias2,
            "clientAlias3": clientBasicInfoComp.current.getClientBasicInfo().clientAlias3,
            "indigenousAncestryId": clientBasicInfoComp.current.getClientBasicInfo().indigenousAncestry?.indigenousAncestryId,
            "createdOn": isCreate ? null : clientBasicInfoComp.current.getClientBasicInfo().createdOn,
            "createdBy": isCreate ? null : clientBasicInfoComp.current.getClientBasicInfo().createdBy,
            "createdOnInCRM": (null == clientBasicInfoComp.current.getClientBasicInfo().createdOnInCRM || "" == clientBasicInfoComp.current.getClientBasicInfo().createdOnInCRM) ? null : new Date(clientBasicInfoComp.current.getClientBasicInfo().createdOnInCRM),
        },
        "clientContactDetails": {
            "clientContactInfoId": isCreate ? 0 : clientContactInfoComp.current.getClientContactInfo().clientContactInfoId,
            "email": clientContactInfoComp.current.getClientContactInfo().email,
            "phoneNumber": clientContactInfoComp.current.getClientContactInfo().phoneNumber,
            "mobileNumber": clientContactInfoComp.current.getClientContactInfo().mobileNumber,
            "PreferredMethodOfContactId": clientContactInfoComp.current.getClientContactInfo().preferredMethodOfContact?.contactMethodId
        },
        "ClientFinancialInformation": {
            "clientFinancialInformationId": isCreate ? 0 : clientFinancialInfoComp.current.getClientFinancialInfo().clientFinancialInformationId,
            "financiallyEligible": clientFinancialInfoComp.current.getClientFinancialInfo().financiallyEligible != null ? Boolean(clientFinancialInfoComp.current.getClientFinancialInfo().financiallyEligible) : null,
            "familySize": clientFinancialInfoComp.current.getClientFinancialInfo().familySize != '' ? clientFinancialInfoComp.current.getClientFinancialInfo().familySize : null,
            "last12MonthsIncome": clientFinancialInfoComp.current.getClientFinancialInfo().last12MonthsIncome != null ? (clientFinancialInfoComp.current.getClientFinancialInfo().last12MonthsIncome != "zero" ? clientFinancialInfoComp.current.getClientFinancialInfo().last12MonthsIncome : 0) : null,
            "last30DaysIncome": clientFinancialInfoComp.current.getClientFinancialInfo().last30DaysIncome != null ? (clientFinancialInfoComp.current.getClientFinancialInfo().last30DaysIncome != "zero" ? clientFinancialInfoComp.current.getClientFinancialInfo().last30DaysIncome : 0) : null,
            "totalEquityValue": clientFinancialInfoComp.current.getClientFinancialInfo().totalEquityValue != null ? (clientFinancialInfoComp.current.getClientFinancialInfo().totalEquityValue != "zero" ? clientFinancialInfoComp.current.getClientFinancialInfo().totalEquityValue : 0) : null,
            "lastAssessmentDate": (null == clientFinancialInfoComp.current.getClientFinancialInfo().lastAssessmentDate || "" == clientFinancialInfoComp.current.getClientFinancialInfo().lastAssessmentDate) ? null : new Date(clientFinancialInfoComp.current.getClientFinancialInfo().lastAssessmentDate),
            "createdOn": isCreate ? null : clientFinancialInfoComp.current.getClientFinancialInfo().createdOn,
            "createdBy": isCreate ? null : clientFinancialInfoComp.current.getClientFinancialInfo().createdBy,
            "mostRecentAssessmentOver24Months": clientFinancialInfoComp.current.getClientFinancialInfo().mostRecentAssessmentOver24Months != null ? Boolean(clientFinancialInfoComp.current.getClientFinancialInfo().mostRecentAssessmentOver24Months) : true
        }
    };

    setTrackEvents(JSON.stringify(newClientDetails));
    appInsights.trackTrace({ message: JSON.stringify(newClientDetails), severityLevel: SeverityLevel.Information });

    if (account) {
        instance.acquireTokenSilent(
            tokenRequest
        ).then(async (response) => {
            if (response) {
                if (isCreate) {
                    await CreateClientFile(newClientDetails, response.accessToken).then((data) => {
                        if (option == 0 && data) {
                            navigate(`${prepareNavigationParameters(Constants.CLIENTFILEVIEWDETAILS, new Array(data))}`) ;
                            
                            window.location.reload();
                        }
                        else if (option == 1 && data) {
                            navigateUrl();
                        }
                    });
                } else {
                    await UpdateClientFile(newClientDetails, response.accessToken).then((data) => {
                        if (option == 1 && response) {
                            navigateUrl();
                        }
                    });
                }
            }
        });
    }
}

    const onCancelClicked = (event) => {

        navigateUrl();
    };

    function navigateUrl() {
        navigate (`${prepareNavigationParameters(null, null, null, getParams())}`); 
    }
    function getParams() {
        var queryParams = '';
        if (backPage == Constants.CLIENTFILEDASHBOARD) {
            queryParams = queryParams + "page="+Constants.SEARCHCLIENTS;
        } else {
            queryParams = queryParams + "page="+Constants.CLIENTFILEVIEWDETAILS;
        }  
        return queryParams;
    };

    const validateInformation = () => {
        if (
            clientBasicInfoComp.current.getClientBasicInfo().firstName && !clientBasicInfoComp.current.getClientBasicInfo().firstNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().lastName && !clientBasicInfoComp.current.getClientBasicInfo().lastNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().dateOfBirth && !clientBasicInfoComp.current.getClientBasicInfo().dateOfBirthValidity) {
            return true;
        }
        return false;
    }

    useEffect(() => {

        const fetchClientDetailNotes = async (token) => {
            await GetClientDetailNotes(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)), 2, 'true', token).then((data) => {
                setClientDetailNotes(data);            
            });
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchClientDetailNotes(response.accessToken);
                }
            });
        }

    }, [account, instance]);

    const [clientReferrals, setClientReferrals] = useState([]);

    useEffect(() => {

        const fetchClientReferralData = async (token) => {
            await GetAllClientReferrals(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)), token).then((data) => {setClientReferrals(data);});
        };

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchClientReferralData(response.accessToken);
                }
            });
        }

    }, [account, instance]);

    useEffect(() => {
        LoadInitialLookUpDataData();
    }, [account, instance]);


    const LoadInitialLookUpDataData = async () => { 

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await FetchLookupData(response.accessToken).then((data) => {
                        if (data)
                            setContactMethods(data.contactMethods);
                            setIndigenousAncestries(data.indigenousAncestries);
                    })
                }
            });
        }
    }

    useEffect(() => {
        const fetchJPBailOutcomeData = async (token) => {
            await GetAllJPBailOutcomes(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)), token).then((data) => {
                setJpBailOutcomes(data);
            });
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchJPBailOutcomeData(response.accessToken);
                }
            });
        }


    }, [account, instance]);

    useEffect(() => {
        const fetchInteractionData = async (token) => {
            await GetAllInteractions(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)), token).then((data) => {
                setInteractions(data);
            });
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await fetchInteractionData(response.accessToken);
                }
            });
        }


    }, [account, instance]);

    const onDeleteClicked = (event) => {

        setTrackEvents("Deleting Client File By Id : " + searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM));
        appInsights.trackTrace({ message: "Deleting Client File By Id : " + searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM), severityLevel: SeverityLevel.Information });
        
        var result = 1;

        var searchParamId = searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM);
        const DeleteData = async (token, Id) => {
            await DeleteClientFile(token, Id).then((data) => {
                if (data) {
                    toast.success("Client File is successfully deleted.", { position: "top-center", autoClose: 5000, theme: "colored" });
                    navigate(`${prepareNavigationParameters(Constants.SEARCHCLIENTS, null , null, null)}`);                    
                } else {
                    throw new Error('Delete Client File was not succesful.');
                }                    
            })
        };      


        const thereAreNoSearchParameters = (null != searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM));
        const thereIsAtLeastOneSearchParameter = (Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) > 0);

        if (thereAreNoSearchParameters && thereIsAtLeastOneSearchParameter) {

            let text;

            const confirmationMessage = "Are you sure you want to delete the Client File and all the details (Notes, Referrals, Etc.) associated with it?";

            // if (window.confirm(confirmationMessage) == true) {

            //     text = "You pressed OK!";

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteData(response.accessToken, searchParamId);
                    }
                });
            }
            // } else {
            //     text = "You canceled!";
            // }
        }

        return result;
    }

    const canSave = () => {
        var result = false;

        if ((null != searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) && (Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) > 0)) {
            result = canUpdate("client-file", null);
        } else {
            result = canCreate("client-file", null);
        }

        return result;
    }

    const canDeleteClientFile = () => {
        return ((null != searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) && (Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) > 0) && canDelete('client-file', null));
    };

    const scrollToCRMHistory = () => {
        const timer = setTimeout(() => {
            if (scrollToCRMHistoryRef.current && fromPageValue == "crmce" && isScrolledToCRMHistory == false) {
                scrollToCRMHistoryRef.current.scrollIntoView({ behavior: 'smooth' }); 
                setIsScrolledToCRMHistory(true);
                setScreenSideBarKey(screenSideBarList[3]);
            }
        }, 100);
        return () => clearTimeout(timer);
    }

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey = `${screenSideBarList?.indexOf(text) + 1}`;
        const element = document.getElementById('scrollByClickedItem' + listItemKey.toString());
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };

    const [openConfirmMarkAsConflictedDialog, setOpenConfirmMarkAsConflictedDialog] = useState(false);
    const onConfirmMarkAsConflictedClickOpen = () => {
        setOpenConfirmMarkAsConflictedDialog(true);
    };
    const onConfirmMarkAsConflictedClickClose = () => {
        setOpenConfirmMarkAsConflictedDialog(false);
    };

    const onMarkAsConflictedClicked = async (event) => {
        setTrackEvents("Marking Client File as conflicted by Id : " + searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM));
        appInsights.trackTrace({ message: "Marking Client File as conflicted by Id : " + searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM), severityLevel: SeverityLevel.Information });
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    MarkClientFileAsConflicted(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)), response.accessToken).then(() => {
                        navigate(`${prepareNavigationParameters(Constants.SEARCHCLIENTS, null , null, null)}`); 
                    });
                }
            });
        }
    }

    const isCreateNewFromSearch = () => {
        var clientFileId = Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM));
        var clientFileCrmId = searchParams.get("crmId");
        return !((clientFileCrmId && clientFileCrmId != "") || (clientFileId && clientFileId > 0));
    }

    const canMarkAsConflicted = () => {
        var result = false;

        if ((canUpdate("client-file", null)) && (Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) && Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) > 0)) {
            if (isLinkedClient) {
                result = true;
            }
            else if (hasDcpDuplicateClientFiles) {
                result = true;
            }
        }

        if (isCrmConflicted || isDcpConflicted) {
            result = false;
        }
        return result;
    }

    function Save() {

        setSaveOption(0);
        onSaveClicked(0);
    }

    function SaveAndClose() {
        setSaveOption(1);
        onSaveClicked(1);
    }

    if (canRead("client-file", null)) {
        if (isDataReady) {
            return (
                <Grid container style={{ padding: "5px 10px 0px 10px", margin: "80px 0px 0px 150px" }}>
                    <SubHeader
                        title="Client File Details"
                        menuItemOneText="Delete Client File"
                        onMenuItemOneClicked={onDeleteClickOpen}
                        isMenuItemOneVisible={canDeleteClientFile()}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText="Mark for Review"
                        onMenuItemTwoClicked={onConfirmMarkAsConflictedClickOpen}
                        isMenuItemTwoVisible={canMarkAsConflicted()}
                        menuItemTwoType="Warning"
                        isMenuItemTwoDisabled={false}
                        navItemOneText="Save"
                        onNavItemOneClicked={Save}
                        isNavItemOneVisible={canSave()}
                        isNavItemOneDisabled={saveClicked}
                        navItemTwoText="Save & Close"
                        onNavItemTwoClicked={SaveAndClose}
                        isNavItemTwoVisible={canSave()}
                        isNavItemTwoDisabled={saveClicked}
                        navItemThreeText="Cancel"
                        onNavItemThreeClicked={onCancelClicked}
                        isNavItemThreeVisible={canSave()}
                        isNavItemThreeDisabled={false}
                        navItemFourText=""
                        onNavItemFourClicked=""
                        isNavItemFourVisible={false}
                        isNavItemFourDisabled={false} />
                    <StyledDialog title="Mark for Review" open={openConfirmMarkAsConflictedDialog} onClose={onConfirmMarkAsConflictedClickClose}
                        message={"Do you really want to mark the CF-" + Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) + " as conficted?"}
                        onConfirmed={onMarkAsConflictedClicked} confirmationText="Mark for Review" />
                    <StyledDialog title="Delete Client File"
                        open={openDeleteDialog}
                        onClose={onDeleteClickClose}
                        message={"Do you really want to delete the Client File :" + Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) + " ? This process cannot be undone."}
                        onConfirmed={onDeleteClicked}
                        confirmationText="Delete" />

                    <StyledDialog title="Client File Already Exists!"
                        open={openCreateDuplicateClientConfirmDialog}
                        onClose={onCreateDuplicateClientConfirmClickClose}
                        message={"There is already a DC Portal Client File with the same First and Last names and date of birth. Are you sure you want to create a new file for this client?"}
                        onConfirmed={onCreateDuplicateClientConfirmClicked}
                        confirmationText="Create Client" isCancelRequired={true} />

                    <ScreenSideBar primary screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey} />
                    <Grid item xs={12} md={12} style={{ marginBottom: "30px" }}>
                        {(isCrmConflicted || isDcpConflicted) && (<>
                            <Badge bg="danger" style={{ marginBottom: "10px" }}>** Needs Review **</Badge>
                        </>)}
                        <div id="scrollByClickedItem1" style={{ scrollMarginTop: "175px" }}>
                            <Grid container style={{ border: "1px solid #7F8284", borderRadius: "8px" }}>
                                <Grid item xs={12} md={12} style={{
                                    textAlign: "left", font: "normal normal bold 17px/22px Franklin Gothic Medium",
                                    letterSpacing: "0px", color: " #000000", opacity: "1", margin: "10px 0px 0px 20px"
                                }}>{screenSideBarList[0]}</Grid>
                                <ClientBasicInfo clientFile={crmData} isReadOnly={(crmData?.clientFileCrmId != null && crmData?.clientFileCrmId != "") || !canUpdateBasicInfo ? true : (clientFileCrmIdById !== "" || !canUpdateBasicInfo ? true : false)} clientFileCrmId={searchParams.get("crmId")} firstName={(isCreateNewFromSearch) ? firstName : ""} middleName={(isCreateNewFromSearch) ? middleName : ""} lastName={(isCreateNewFromSearch) ? lastName : ""} dateOfBirth={(isCreateNewFromSearch) ? dateOfBirth : ""} clientFileId={searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)} ref={clientBasicInfoComp} indigenousAncestries={indigenousAncestries} isAliasVisible={true} fromViewDeatils />
                                <ClientContactInfo clientFile={crmData} clientFileCrmId={searchParams.get("crmId")} clientFileId={searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)} ref={clientContactInfoComp} contactMethods={contactMethods} fromViewDeatils />
                                <ClientFinancialInfo clientFile={crmData} clientFileCrmId={searchParams.get("crmId")} isCRMOnly={true} clientFileId={searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)} ref={clientFinancialInfoComp} fromViewDeatils />
                            </Grid>
                        </div>
                        <div id="scrollByClickedItem2" style={{ scrollMarginTop: "169px" }}><NoteTable title={screenSideBarList[1]} notes={clientDetailNotes} clientFileId={Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM))} relatedId={Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM))} noteTypeId={2} isDisable={false} pageId={"clientfile"} pageParams={``} /></div>
                        <div id="scrollByClickedItem3" style={{ scrollMarginTop: "169px" }}><InteractionList title={screenSideBarList[2]} interactions={interactions} clientFileId={Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM))} /></div>
                        <div id="scrollByClickedItem4" ref={scrollToCRMHistoryRef} style={{ scrollMarginTop: "169px" }}>
                            <Grid container style={{ border: "1px solid #7F8284", margin: "10px 0px 10px 0px", backgroundColor: "#ececec", borderRadius: "8px" }}>
                                {/* <DocketsLegalIssues dataGridtitle="Dockets & Legal Issues" hideHeader legalIssues={legalIssues} clientFileId={Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM))} /> */}
                                <CertificateDataGrid dataGridtitle="Certificate" hideHeader certificates={certificates} />
                                {/* <CertificateOutcome dataGridtitle="Certificate Outcome" hideHeader certificatesOutcomes={certificatesOutcomes} /> */}
                                <ServiceDecision dataGridtitle="Service Decision" hideHeader serviceDecisions={serviceDecisions} clientFileId={Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM))} />
                            </Grid>
                        </div>
                        <div id="scrollByClickedItem5" style={{ scrollMarginTop: "169px" }}><JPBailOutcomeList title={screenSideBarList[4]} jpBailOutcomes={jpBailOutcomes} clientFileId={Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM))} /></div>
                        <div id="scrollByClickedItem6" style={{ scrollMarginTop: "169px" }}>
                            <Grid container style={{ border: "1px solid #7F8284", borderRadius: "8px" }}>
                                <ReferralTable title="Client Referrals" fromViewDeatils referrals={clientReferrals} clientFileId={Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM))} />
                                <AgencyRequestList title="Agency Requests" fromViewDeatils agencyRequests={agencyRequests} clientFileId={Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM))} />
                                <ClientGatewayTable title="Client Gateway Details" clientGatewayDetails={clientGatewayDetails} clientFileId={Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM))} />
                            </Grid>

                        </div>

                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Backdrop />
            );
        }
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>Client Details
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view these details **
                </Grid>
            </Grid>
        );
    }
}

export default ClientFile;